<template>
  <v-container class="pa-0"
    ><div class="recalled-product-detail" v-if="article">
      <div class="action-icons">
        <v-icon>$richiamoProdotti</v-icon>
      </div>
      <v-img
        v-if="image"
        :src="image"
        :alt="imageAlt"
        class="my-3"
        contain
        max-height="150px"
        min-height="100px"
      />

      <div class="pa-2">
        <div class="mb-3">
          <div v-if="article.metaData.articletype.product_date">
            {{
              $dayjs(article.metaData.articletype.product_date).format(
                "DD/MM/YYYY"
              )
            }}
          </div>
          <h4 class="my-0 primary--text">{{ article.title }}</h4>
          <div>{{ article.metaData.articletype.short_desc }}</div>
        </div>

        <div
          v-html="$ebsn.meta(article, 'articletype.recipe_description')"
        ></div>

        <div v-html="article.content"></div>
      </div></div
  ></v-container>
</template>
<style lang="scss">
.recalled-product-detail {
  h4 {
    line-height: 20px;
  }
  .action-icons {
    position: absolute;
    z-index: 2;
    left: 15px;
    top: 15px;
    .v-icon {
      color: #e65954 !important;
    }
  }
}
</style>
<script>
import article from "~/mixins/article";
import has from "lodash/has";
export default {
  name: "RecalledProductDetails",
  mixins: [article],
  data() {
    return {
      article: null
    };
  },
  computed: {
    image() {
      return has(this.article, "media[0].url")
        ? this.article.media[0].url
        : this.article.refImageUrl
        ? this.article.refImageUrl
        : "";
    },
    imageAlt() {
      return this.article.title || this.article.title;
    }
  },
  methods: {
    setTitle() {
      this.$route.meta.title = this.article.title;
      global.EventBus.$emit("resetNavbar");
    }
  },
  async created() {
    await this.reload(this.$route.params.pageName);
    this.setTitle();
  },
  watch: {
    "$route.params.pageName": async function(pageName) {
      await this.reload(pageName);
      this.setTitle();
    }
  }
};
</script>
