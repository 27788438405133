var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0" }, [
    _vm.article
      ? _c(
          "div",
          { staticClass: "recalled-product-detail" },
          [
            _c(
              "div",
              { staticClass: "action-icons" },
              [_c("v-icon", [_vm._v("$richiamoProdotti")])],
              1
            ),
            _vm.image
              ? _c("v-img", {
                  staticClass: "my-3",
                  attrs: {
                    src: _vm.image,
                    alt: _vm.imageAlt,
                    contain: "",
                    "max-height": "150px",
                    "min-height": "100px"
                  }
                })
              : _vm._e(),
            _c("div", { staticClass: "pa-2" }, [
              _c("div", { staticClass: "mb-3" }, [
                _vm.article.metaData.articletype.product_date
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .$dayjs(
                                _vm.article.metaData.articletype.product_date
                              )
                              .format("DD/MM/YYYY")
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _c("h4", { staticClass: "my-0 primary--text" }, [
                  _vm._v(_vm._s(_vm.article.title))
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.article.metaData.articletype.short_desc))
                ])
              ]),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$ebsn.meta(
                      _vm.article,
                      "articletype.recipe_description"
                    )
                  )
                }
              }),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.article.content) }
              })
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }